import { useState, useEffect, useMemo, useCallback } from 'react';
import logo from './logo.svg';
import './App.css';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';

async function fetchOne(url) {
  const response = await fetch(url);
  return response.json();
}

async function fetchMany(urls) {
  return Promise.all(urls.map(url => fetchOne(url)));
}

function serializeValue(value: unknown, i: string | undefined) {
  if (i) {
    if (i.key === "lvts") {
      return new Date(value * 1000).toISOString();
    }
  }
  if (typeof value === 'string') {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(',') ? `"${formattedValue}"` : formattedValue;
  }
  return value;
}

function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

export async function exportToCsv(columns, rows, fileName) {
  const content = [columns.map(({name}) => serializeValue(name)).join(",")];
  content.push(...rows.map(row => 
    columns.map(({key}, i) => serializeValue(row[key] ?? '', columns[i])).join(",")
  ));
  downloadFile(fileName, new Blob([content.join("\n")], { type: 'text/csv;charset=utf-8;' }));
}

function HFlex({ style, children }) {
  return <div style={{display: 'flex', flexDirection: 'row', ...style}}>{children}</div>
}

function format(field, fn) {
  return ({row}) => fn({value: row[field]})
}

function CurrencyFormatter({ value }) {
  if (!Number.isFinite(value))
    return value;
  return <>V {value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</>
}

function DateFormatter({ value, ...r }) {
  if (value > 0) {
    const v = new Date(value * 1000).toLocaleString();
    return <>{v}</>;
  } else {
    return <>Expired</>
  }
}

function PercentFormatter({ value }) {
  if (!Number.isFinite(value))
    return value;
  return <>{value.toFixed(2)}%</>
}

const known = [
  'Algorand Inc',
  'Algorand Foundation',
  'NFDomains',
];

function parseAddressBookOwner(owner) {
  for(const k of known) {
    if (owner.includes(k))
      return k
  }
  return owner;
}

function getComparator(sortColumn: string): Comparator {
  return (a, b) => {
    return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] < b[sortColumn] ? 1 : -1;
  };
}

function App() {
  const [columns, setColumns] = useState([
    { key: 'a', name: 'Address' },
    // { key: 'o', name: 'Owner' },
    // { key: 'v', name: 'DeFi' },
    { key: 'b', name: 'Balance', formatter: format('b', CurrencyFormatter) },
    { key: 'nob', name: 'N.O. Balance', formatter: format('nob', CurrencyFormatter) },
    { key: 'perc', name: 'Voting %', formatter: format('perc', PercentFormatter) },
    { key: 'ur', name: 'Update Round' },
    { key: 'fv', name: 'First Valid' },
    { key: 'lv2', name: 'Last Valid' },
    { key: 'lvts', name: 'Est. Expiration', formatter: format('lvts', DateFormatter) },
    { key: 'kd', name: 'Key Dilution' },
  ]);
  const [rows, setRows] = useState([]);
  const [updated, setUpdated] = useState(null);
  const [sortColumns, setSortColumns] = useState([{ columnKey: "b", direction: "ASC" }]);
  const [totals, setTotals] = useState({});
  const [addressBook, setAddressBook] = useState();
  
  const rerender = useCallback(() => {
    console.log("rerenbder");
    setColumns([...columns]);
  }, [columns]);

  useEffect(() => {
    console.log('sc', sortColumns);
  }, [sortColumns]);

  // const topRow = useMemo(() => {
  //   return [{ a: 'Total (${totals.count})', b: 2, nob: 3}];
  // }, [totals]);

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;
    console.log("sorting rows");
    const sorted = [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sorted;
  }, [rows, sortColumns]);

  useEffect(() => {
    (async() => {
      const [addr1, addr2, data, vaults] = await fetchMany([
        "https://flow.algo.surf/address-book.json",
        "/address-book.json",
        'https://voi-cons-data.pages.dev/latest.json',
        'https://voi-cons-data.pages.dev/vaults.json',
      ]);
      const addressBook = {...addr1, ...addr2};
      setAddressBook(addressBook);
      setUpdated(data.ts);
      const totalB = data.onl.reduce((sum, {b}) => sum+b, 0);
      const totalNOB = data.onl.reduce((sum, {nob}) => sum+nob, 0);
      setTotals({b: totalB, nob: totalNOB, count: data.onl.length});
      setRows(data.onl.map(({a, nob, b, fv, lv2, lvts, kd, ur, Z}) => {
        let o = '';
        let v = 'No';
        if (addressBook[a]) {
          const label = parseAddressBookOwner(addressBook[a]);
          o = label;
        } 
        if (vaults[a]) {
          const vData = vaults[a];
          let {v: version} = vData;
          if (version === 'ff')
            v = 'Yes (Folks)'
          else
            v = `Yes (AlgoFi v${version})`;
          if (!o)
            o = vData.owner;
        }
        return {
          a,
          o,
          b,
          nob,
          perc: b / totalB * 100,
          fv,
          lv2,
          lvts,
          kd,
          ur,
          v,
        }
      }));
    })()
  }, []);

  const gridElement = <DataGrid
    columns={columns}
    rows={sortedRows}
    onRowsChange={setRows}
    sortColumns={sortColumns}
    onSortColumnsChange={setSortColumns}
    defaultColumnOptions={{
      sortable: true,
      resizable: true
    }}
    /* topSummaryRows={topRow} */
    className="fill-grid"
    rowKeyGetter={rowKeyGetter}
    style={{flexGrow: 1, resize: 'both'}} />;

  const exportAction = useCallback(() => exportToCsv(columns, sortedRows, 'voi-consensus.csv'), [columns, sortedRows]);

  return (
    <div className="App">
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <HFlex>
          <div style={{flexGrow: 1, textAlign: 'left'}}>Voi Consensus Ledger Data &middot; By <a target="_blank" href="https://twitter.com/d13_co">D13.co</a> &middot; For detailed node metrics <a target="_blank" href="https://voi-node-info.boeieruurd.com/">see here</a> &middot; Updated: {new Date(updated).toLocaleString()}</div>
          <button onClick={exportAction}>Export</button>
        </HFlex>
        {gridElement}
      </div>
    </div>
  );
}

function rowKeyGetter(row: Row) {
  return row.a;
}

export default App;
